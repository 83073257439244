<template>
    <svg id="svg-fav" xmlns="http://www.w3.org/2000/svg"
     width="1.77778in" height="1.77778in"
     viewBox="0 0 128 128">
  <path
        id="fav-1"
        fill="none" stroke="black" stroke-width="1"
        d="M 42.00,9.00
           C 46.57,13.10 59.64,30.47 62.00,36.00
             62.00,36.00 85.00,9.00 85.00,9.00
             82.95,15.50 75.10,26.69 71.19,33.00
             68.43,37.45 66.73,41.50 62.00,44.00
             62.00,44.00 42.00,9.00 42.00,9.00 Z
           M 38.00,11.00
           C 38.00,11.00 42.87,26.00 42.87,26.00
             42.87,26.00 49.00,50.00 49.00,50.00
             49.00,50.00 22.00,44.37 22.00,44.37
             22.00,44.37 8.00,40.00 8.00,40.00
             8.00,40.00 43.00,45.00 43.00,45.00
             43.00,45.00 38.00,11.00 38.00,11.00 Z
           M 88.00,11.00
           C 88.00,11.00 87.41,23.00 87.41,23.00
             87.41,23.00 84.00,45.00 84.00,45.00
             84.00,45.00 106.00,41.21 106.00,41.21
             110.48,40.69 114.66,39.30 119.00,41.21
             119.00,41.21 78.00,50.00 78.00,50.00
             78.00,50.00 88.00,11.00 88.00,11.00 Z
           M 6.00,44.00
           C 6.00,44.00 23.00,52.58 23.00,52.58
             23.00,52.58 43.00,65.00 43.00,65.00
             43.00,65.00 18.00,79.42 18.00,79.42
             14.40,81.49 10.29,84.88 6.00,84.00
             6.00,84.00 34.00,64.00 34.00,64.00
             34.00,64.00 6.00,44.00 6.00,44.00 Z
           M 84.00,64.00
           C 84.00,64.00 109.00,49.58 109.00,49.58
             112.60,47.51 116.71,44.12 121.00,45.00
             121.00,45.00 93.00,65.00 93.00,65.00
             99.45,69.04 115.58,79.70 120.00,85.00
             120.00,85.00 101.00,74.85 101.00,74.85
             101.00,74.85 84.00,64.00 84.00,64.00 Z
           M 8.00,88.00
           C 8.00,88.00 49.00,79.00 49.00,79.00
             49.00,79.00 43.37,101.00 43.37,101.00
             43.37,101.00 38.00,118.00 38.00,118.00
             38.00,118.00 43.00,84.00 43.00,84.00
             43.00,84.00 19.00,88.00 19.00,88.00
             19.00,88.00 8.00,88.00 8.00,88.00 Z
           M 78.00,79.00
           C 78.00,79.00 104.00,84.63 104.00,84.63
             104.00,84.63 119.00,89.00 119.00,89.00
             119.00,89.00 84.00,84.00 84.00,84.00
             84.00,84.00 87.41,106.00 87.41,106.00
             87.41,106.00 88.00,118.00 88.00,118.00
             88.00,118.00 78.00,79.00 78.00,79.00 Z
           M 63.00,85.00
           C 63.00,85.00 73.05,99.00 73.05,99.00
             73.05,99.00 85.00,120.00 85.00,120.00
             85.00,120.00 63.00,93.00 63.00,93.00
             59.20,99.01 47.00,115.83 42.00,120.00
             43.93,113.39 58.32,91.11 63.00,85.00 Z" />


  <path
        id="fav-2"
        fill="blue" stroke="blue" stroke-width="1"
        d="
           M 53.00,41.00
           C 57.40,44.26 58.91,45.40 58.00,51.00
             52.52,48.89 53.05,46.34 53.00,41.00 Z
           M 69.00,51.00
           C 67.94,45.77 69.36,44.27 74.00,42.00
             73.36,46.72 73.72,49.11 69.00,51.00 Z
           M 40.00,55.00
           C 44.81,55.02 47.04,54.12 49.00,59.00
             43.98,60.02 43.05,58.91 40.00,55.00 Z
           M 78.00,60.00
           C 79.04,54.12 81.74,55.02 87.00,55.00
             83.87,59.02 83.08,59.59 78.00,60.00 Z
           M 40.00,74.00
           C 43.05,70.09 43.98,68.98 49.00,70.00
             47.04,74.88 44.81,73.98 40.00,74.00 Z
           M 77.00,70.00
           C 82.47,68.86 83.59,69.63 87.00,74.00
             81.76,73.98 80.00,74.69 77.00,70.00 Z
           M 53.00,88.00
           C 53.12,82.84 52.73,80.07 58.00,78.00
             58.91,83.60 57.40,84.74 53.00,88.00 Z
           M 69.00,78.00
           C 73.72,79.89 73.36,82.28 74.00,87.00
             69.36,84.73 67.94,83.23 69.00,78.00 Z
             " />
</svg>
<div style="position: absolute; top: 0px;" >
    <div class="box" >
        <ProgressSpinner />
    </div>
</div>
</template>

<script>
// @ is an alias to /src
import store from '@/store'
import ProgressSpinner from 'primevue/progressspinner';


export default {
    name: 'AdminLogin',
    components: {
        ProgressSpinner,
    },
    data: function() {
        return {
        }
    },


    async beforeRouteEnter(route, redirect, next) {
        if(await store.dispatch("auth/checkAuth")){
            // await store.dispatch("auth/getUserInfo")
            next(vm => vm.pushToPath("/admin"))
        } else {
            next(vm => vm.pushToPath("/admin_auth_login_door"))
        }
    },

    methods: {
        async onAuthButton() {
            await this.$store.dispatch('auth/authPoint')
        },

        pushToPath(path) {
            this.$router.push({ path: path });
        },

    }
}
</script>

<style scoped>
svg {
  width: 32px;
  height: 32px;
  /* width: 50px; */
  /* height: 50px; */
}
#svg-fav {
    display: inline-block;
    font-size: 3em;
    animation: r1 6s linear infinite;
}
@keyframes r1 {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
svg path#fav-1 {
  fill: none;
  stroke: #00183d;
  stroke-linejoin: round;
  stroke-dasharray: 500px;
  stroke-dashoffset: 500px;
  animation: line_animation 3s infinite both;
}

svg path#fav-2 {
  fill: #00a2c4;
  stroke: #00a2c4;
  stroke-linejoin: round;
  stroke-dasharray: 500px;
  stroke-dashoffset: 500px;
  animation: line_animation 3s infinite both;
}
@keyframes line_animation {
  0% {
    stroke-dashoffset: 500px;
    fill-opacity: 0.1;
  }
  100% {
    stroke-dashoffset: 0px;
    fill-opacity: 1;
  }
}
.box {
    width: 100vw;
    height: 100vh;
      display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
</style>
